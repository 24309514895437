import React, { useEffect, useState } from "react";
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import settings from "../../../settings";
import { Link } from "react-router-dom";
import Title from "../../Helpers/Titles";
import OrderRequest from "../Schools/School/OrderRequest";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(4, 0),
    },
    actionBtns: {
        margin: theme.spacing(0, 1),
    },
    schoolLinks: {
        color: "#424242",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function AllContracts() {
    const classes = useStyles();

    const token = localStorage.getItem("pes-crm-token");
    const [contracts, setContracts] = useState([]);
    const [schoolInfo, setSchoolInfo] = useState({});
    const [correctSchool, setCorrectSchool] = useState(false);
    const [contractId, setContractId] = useState(null);
    const [openContract, setOpenContract] = useState(false);

    const [contractAdded, setContractAdded] = useState(false);

    const [popupMeassge, setPopupMessage] = useState("");

    const [contractsDisplay, setContractsDisplay] = useState([]);

    const [filter, setFilter] = useState("");
    const [contractIdFilter, setContractIdFilter] = useState("");

    const isManager = JSON.parse(localStorage.getItem("user-data")).crmRoleId === 2;

    useEffect(() => {
        fetch(settings.api().contract, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setContracts(data);
                setContractsDisplay(data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     console.log("effect filter");

    //     if (filter !== "") {
    //         setContractsDisplay(contracts.filter((c) => c.status.includes(filter)));
    //     } else {
    //         setContractsDisplay(contracts);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [filter]);

    const handleContractIdFilter = () => {
        fetch(settings.api().contract + `?contractId=${contractIdFilter}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);

                setContracts(data);
                // setContractsDisplay(data);
                if (filter !== "") {
                    setContractsDisplay(data.filter((c) => c.status.includes(filter)));
                } else {
                    setContractsDisplay(data);
                }
            });
    };

    const downloadContract = async (cId) => {
        const token = localStorage.getItem("pes-crm-token");
        const response = await fetch(settings.api().contract + "/" + cId + "/download", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "ContractID" + cId + ".pdf";
            a.click();
        });
    };
    const confirmSchool = (sID, cID) => {
        setContractId(cID);
        setCorrectSchool(true);
        fetch(settings.api().schools + `/${sID}/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => setSchoolInfo(data));
    };

    const getDateFormat = (dateString) => {
        let d = new Date(dateString);
        d = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        let hour = d.getHours();
        let min = d.getMinutes();

        let ampm = "am";
        if (hour > 12) {
            hour -= 12;
            ampm = "pm";
        }
        if (min < 10) {
            min = "0" + min;
        }

        return year + "-" + month + "-" + date + " " + hour + ":" + min + " " + ampm;
    };

    const txt_ContractState_Changed = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div>
            <Title title="Contract List" subtitle="Contracts" />
            <TableContainer component={Paper} className={classes.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={200} colSpan={6}>
                                <TextField
                                    size="small"
                                    label="Filter by Contract ID"
                                    variant="outlined"
                                    value={contractIdFilter}
                                    onChange={(e) => setContractIdFilter(e.target.value)}
                                    style={{ marginRight: "10px" }}
                                />

                                <FormControl
                                    variant="outlined"
                                    style={{ marginRight: "10px", width: "200px" }}
                                    size="small"
                                >
                                    <InputLabel id="filter-contract-state">Filter by Contract State</InputLabel>
                                    <Select
                                        labelId="filter-contract-state"
                                        value={filter}
                                        onChange={txt_ContractState_Changed}
                                        label="Filter by Contract State"
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        <MenuItem value="Incomplete">Incomplete</MenuItem>
                                        <MenuItem value="Sent to Customer">Sent to Customer</MenuItem>
                                        <MenuItem value="Changes Requested">Changes Requested</MenuItem>
                                        <MenuItem value="Signed">Signed</MenuItem>
                                        <MenuItem value="Completed">Completed</MenuItem>
                                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button variant="contained" color="primary" onClick={handleContractIdFilter}>
                                    Search
                                </Button>
                            </TableCell>

                            <TableCell colSpan={2} align="right">
                                <Typography variant="subtitle1">
                                    Number of Records: {contractsDisplay.length}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Contract ID</TableCell>
                            <TableCell align="center">Contract State</TableCell>
                            <TableCell align="center">School Name</TableCell>
                            <TableCell align="center">City</TableCell>
                            <TableCell align="center">School Year</TableCell>
                            <TableCell align="center">Created</TableCell>
                            <TableCell align="center">Modified</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractsDisplay.length !== 0 &&
                            contractsDisplay.map((c) => (
                                // contractsDisplay.reverse().map((c) => (
                                <TableRow key={c.contractId}>
                                    <TableCell>{c.contractId}</TableCell>
                                    <TableCell align="center">{c.status}</TableCell>
                                    <TableCell align="center">
                                        <Link to={`/crm/schools/${c.schoolId}`} className={classes.schoolLinks}>
                                            {c.schoolName}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">{c.city}</TableCell>
                                    <TableCell align="center">{c.schoolYear}</TableCell>
                                    <TableCell align="center">{getDateFormat(c.createdUtc)}</TableCell>
                                    <TableCell align="center">{getDateFormat(c.modifiedUtc)}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.actionBtns}
                                            onClick={() => downloadContract(c.contractId)}
                                        >
                                            Download
                                        </Button>
                                        {c.schoolYear === "2025-2026" &&
                                            (isManager || (c.status !== "Completed" && c.status !== "Signed")) && (
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => confirmSchool(c.schoolId, c.contractId)}
                                                    className={classes.actionBtns}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={correctSchool} onClose={() => setCorrectSchool(false)}>
                <DialogTitle>
                    <Typography variant="subtitle1">
                        Edit contract for <strong>{schoolInfo.schoolName}</strong>
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText variant="subtitle1">
                        Please confirm that you have the correct <u>{schoolInfo.schoolName}</u>:<br />
                        <br />
                        <Typography variant="subtitle1">
                            Address:{" "}
                            <strong>
                                {schoolInfo.address1}, {schoolInfo.city}, {schoolInfo.provCode}, {schoolInfo.postal}
                                <br />
                            </strong>
                            Board: <strong>{schoolInfo.boardName}</strong>
                            <br />
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setCorrectSchool(false);
                            setOpenContract(true);
                        }}
                        color="primary"
                    >
                        Continue
                    </Button>
                    <Button onClick={() => setCorrectSchool(false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen
                open={openContract}
                onClose={() => setOpenContract(true)}
                TransitionComponent={Transition}
                scroll="paper"
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setOpenContract(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography componet="h1" variant="h6" color="inherit" noWrap>
                            Edit Contract
                        </Typography>
                    </Toolbar>
                </AppBar>
                <OrderRequest
                    schoolInfo={schoolInfo}
                    closeDialog={() => setOpenContract(false)}
                    contractStatus={"EDIT"}
                    contractId={contractId}
                    success={(title) => {
                        setPopupMessage(title);
                        setContractAdded(true);
                    }}
                />
            </Dialog>

            <Dialog open={contractAdded}>
                <DialogTitle>
                    <Typography variant="subtitle1">{popupMeassge}</Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            window.location.reload();
                            setContractAdded(false);
                        }}
                        color="primary"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
